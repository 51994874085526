<template>
  <v-app>
    <home-app-bar />

    <home-view />
  </v-app>
</template>

<script>
  export default {
    name: 'HomeLayoutClient',

    components: {
      HomeAppBar: () => import('@/layouts/homeclient/AppBar'),
      HomeView: () => import('@/layouts/homeclient/View'),
    },
  }
</script>
